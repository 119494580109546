<template>
  <div class="kurset-wrapper container">
    <h2 class="text-center mb-4">Kurset Tona</h2>
    <p class="lead text-center mb-5">Zgjidhni kursin që i përshtatet nevojave tuaja për mësimin e gjuhës gjermane</p>
    
    <div class="table-responsive">
      <table class="table table-striped table-hover">
        <thead class="thead-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Shërbimi</th>
            <th scope="col">Çmimi</th>
            <th scope="col">Kohëzgjatja</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(kurs, index) in kurset" :key="index">
            <th scope="row">{{ index + 1 }}</th>
            <td>
              <strong>{{ kurs.emri }}</strong>
              <p class="small text-muted mb-0" v-if="kurs.pershkrimi">{{ kurs.pershkrimi }}</p>
            </td>
            <td>
              <div v-for="(cmim, i) in kurs.cmimi" :key="i">
                {{ cmim }}
              </div>
            </td>
            <td>
              <div v-for="(kohe, i) in kurs.kohezgjatja" :key="i">
                {{ kohe }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    
    <div class="info-section mt-5">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Informacion shtesë</h5>
          <p class="card-text">
            Për regjistrim dhe më shumë informacion, ju lutemi na kontaktoni ose vizitoni qendrën tonë.
            Ofertat e kurseve mund të ndryshojnë sipas kërkesave dhe sezonit.
          </p>
          <button class="btn btn-primary">Na kontaktoni</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

export default {
  name: 'Kurset',
  data() {
    return {
      kurset: [
        {
          emri: 'Kurs intensiv (A1, A2, B1, B2)',
          cmimi: [
            'Muaji i parë: 90 €',
            'Muaji i dytë: 70 €',
            'Muaji i tretë: 70 €'
          ],
          kohezgjatja: [
            'Hënë - Premte',
            'Çdo ditë 2 orë mësimore'
          ]
        },
        {
          emri: 'Kurs jo-intensiv (A1, A2, B1, B2)',
          cmimi: [
            'Muaji i parë: 60 €',
            'Muajt pasues: 45 €'
          ],
          kohezgjatja: [
            '3 Ditë në javë',
            '2 orë mësimore në ditë'
          ]
        },
        {
          emri: 'Kurs për fëmijë',
          cmimi: [
            'Muaji i parë: 40 €',
            'Muajt pasues: 25 €'
          ],
          kohezgjatja: [
            '2 Ditë në javë',
            '2 orë mësimore në ditë'
          ]
        },
        {
          emri: 'Kurset Konverzacion',
          cmimi: [
            '40 € për muaj'
          ],
          kohezgjatja: [
            'Çdo të shtunë nga 4 orë mësimore'
          ]
        },
        {
          emri: 'Kurset për Infermierë B1',
          cmimi: [
            '150 € gjithsej'
          ],
          kohezgjatja: [
            '60 orë mësimore',
            'Zakonisht mbahen gjatë ditës së shtunë'
          ]
        },
        {
          emri: 'Kurset për Infermierë B2',
          cmimi: [
            '200 € gjithsej'
          ],
          kohezgjatja: [
            '80 orë mësimore',
            'Zakonisht mbahen gjatë ditës së shtunë'
          ]
        },
        {
          emri: 'Gjermanisht për profesionin e mjekësisë B1/C1',
          pershkrimi: 'Parapërgatitje për provimin profesional të gjuhës për mjekët',
          cmimi: [
            '250 € gjithsej'
          ],
          kohezgjatja: [
            '100 orë mësimore',
            'Zakonisht mbahen gjatë ditës së shtunë'
          ]
        },
        {
          emri: 'Kurset përgatitore për provimet e niveleve A1, A2, B1 dhe B2',
          cmimi: [
            '150 € gjithsej'
          ],
          kohezgjatja: [
            '32 orë mësimore',
            'Zakonisht mbahet gjatë fundjavës'
          ]
        },
         {
          emri: 'Perkthimet zyrtare të dokumentave Gjermanisht-Shqip dhe anasjelltas',
          cmimi: [
            '-'
          ],
          kohezgjatja: [
            '-',
          ]
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.kurset-wrapper {
  padding: 2rem 0;
  
  h2 {
    color: #448191;
    font-weight: 600;
  }
  
  .table {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    
    thead {
      background-color: #448191;
      color: white;
    }
    
    th, td {
      vertical-align: middle;
      padding: 1rem;
    }
    
    tr:hover {
      background-color: rgba(68, 129, 145, 0.05);
    }
  }
  
  .info-section {
    .card {
      border-radius: 8px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      
      .card-title {
        color: #448191;
      }
      
      .btn-primary {
        background-color: #448191;
        border-color: #448191;
        
        &:hover {
          background-color: darken(#448191, 10%);
          border-color: darken(#448191, 10%);
        }
      }
    }
  }
}
</style>